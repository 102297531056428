@import "portal-variables";

body {
	background-color: #ffffff;
	font-family: 'Raleway', sans-serif;
	font-weight: 400;
	color: $greyText;
}

.navbar-expand-md,
.navbar-expand-lg {
	flex-wrap: wrap;
	justify-content: center;
	text-align: left;
}

.navbar-brand {
	flex: 0 0 100%;
	text-align: center;
}

caption {
	font-family: 'Nothing You Could Do', cursive;
}

@media (min-width: 1200px) {
	.container {
		max-width: 1164px;
	}
}

.bg-light {
	background-color: #ffffff !important;
}

.btn-primary {
	background-color: $greenMedium;
	border-color: $greenMedium;
	color: $greenDark;
	font-size: 18px;
	line-height: 18px;
	font-weight: 600;
	text-transform: uppercase;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	padding: 13px 20px;

	&:hover,
	&:focus,
	&.focus,
	&:active {
		background-color: #87b38c !important;
		border-color: #87b38c !important;
		color: $greenDark !important;
	}

	&:focus,
	&.focus,
	&:active {
		-webkit-box-shadow: 0 0 0 0.2rem rgba(245, 159, 11, 0.95) !important;
		-moz-box-shadow: 0 0 0 0.2rem rgba(245, 159, 11, 0.95) !important;
		box-shadow: 0 0 0 0.2rem rgba(245, 159, 11, 0.95) !important;
	}

	svg {
		width: 20px;
		height: 13px !important;
		fill: $greenDark !important;
		margin-bottom: 0px !important;
		vertical-align: initial;
		margin-left: 5px;
	}
}

.btn-secondary {
	background-color: $yellow;
	border-color: $yellow;
	color: #ffffff;
	font-size: 18px;
	line-height: 18px;
	font-weight: 600;
	text-transform: uppercase;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	padding: 0px 20px;
	height: 40px;
	font-weight: 500;

	&:hover,
	&:focus,
	&.focus,
	&:active {
		background-color: $darkYellow !important;
		border-color: $darkYellow !important;
	}

	&:focus,
	&.focus,
	&:active {
		-webkit-box-shadow: 0 0 0 0.2rem rgba(245, 159, 11, 0.95) !important;
		-moz-box-shadow: 0 0 0 0.2rem rgba(245, 159, 11, 0.95) !important;
		box-shadow: 0 0 0 0.2rem rgba(245, 159, 11, 0.95) !important;


	}
}

main {
	a {
		color: currentColor;
		text-decoration: underline;

		&:hover {
			color: currentColor;
			text-decoration: none;
		}
	}
}

section {
	display: block;
	width: 100%;
	float: left;
}

ul,
ol,
li {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}

.carousel-control-next,
.carousel-control-prev {
	width: 9%;
}

@media (max-width: 768px) {

	.carousel-control-next,
	.carousel-control-prev {
		width: 10%;
		display: none;
	}
}

.carousel-indicators li {
	box-sizing: content-box;
	-ms-flex: 0 1 auto;
	flex: 0 1 auto;
	width: 15px;
	height: 15px;
	margin-right: 7px;
	margin-left: 7px;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	background-color: #ffffff;
	border: 0px solid #ffffff;
	margin-bottom: 15px;

	&.active {
		background-color: $yellow;
	}
}
