@import "portal-variables";

footer {
	font-size: 14px;
	border-top: 1px solid #efeff0;
	display: table;
	width: 100%;

	&>div {
		display: flex;
		padding: 60px 0;
		justify-content: space-between;
		line-height: 22px;

		&>div {
			span {
				color: $greenDark;
				font-weight: 500;
			}
		}

		ul {
			li {
				text-align: right;

				a {
					color: $greyMenu;
					text-transform: uppercase;

					&:hover {
						color: $greyMenu;
					}
				}
			}
		}
	}

	@media (max-width: 767px) {
		&>div {
			flex-wrap: wrap;
			display: flex;

			&>div {
				flex: 0 0 100% !important;
				text-align: center;

				ul {
					li {
						text-align: center;
						margin: 10px 0;
					}
				}

				&:last-child {
					margin-top: 30px;
				}
			}
		}
	}


}

.cd-top {
	display: inline-block;
	height: 40px;
	width: 40px;
	position: fixed;
	bottom: 40px;
	right: 10px;
	overflow: hidden;
	visibility: hidden;
	opacity: 0;
	z-index: 500;

	svg {
		width: 40px;
		height: 40px;
		fill: $yellow;
	}

	@media (max-width: 767px) {
		&.cd-top--show {
			/* the button becomes visible */
			visibility: visible;
			opacity: 1;
		}
	}

	&.cd-top--fade-out {
		/* if the user keeps scrolling down, the button is out of focus and becomes less visible */
		opacity: .8;
	}

	&:hover {
		opacity: 1;
	}
}
