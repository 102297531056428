@import "portal-variables";

/* jumbtron*/

.jumbotron {
	margin: 0px;
	padding: 0px 0px 60px 0px;
	background: #ffffff;
	/* Old browsers */
	background: -moz-linear-gradient(top, #ffffff 0%, #ffffff 17%, $greenLight 17%, $greenLight 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 17%, $greenLight 17%, $greenLight 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #ffffff 0%, #ffffff 17%, $greenLight 17%, $greenLight 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='$greenLight', GradientType=0);
	/* IE6-9 */
	text-align: center;

	figure {
		position: relative;
	}

	.btn {
		margin-top: 16px;
	}

	figure .caption {
		background-color: $greenMedium;
		color: $greenDark;
		font-family: 'Nothing You Could Do', cursive;
		font-size: 40px;
		line-height: 50px;
		padding: 50px 40px;
		position: absolute;
		bottom: -60px;
		left: -40px;
		width: 495px;
		text-align: left;

		span {
			font-size: 18px;
			font-family: 'Raleway', sans-serif;
			line-height: 20px;
		}
	}

	h1 {
		font-size: 32px;
		font-weight: 400;
		text-align: center;
		margin-top: 110px;
		margin-bottom: 12px;
	}

	p.lead {
		font-size: 22px;
		line-height: 32px;
		text-align: center;
		font-weight: 400;
	}

	&.internal {
		/* Old browsers */
		background: -moz-linear-gradient(top, #ffffff 0%, #ffffff 12%, $greenLight 12%, $greenLight 100%);
		/* FF3.6-15 */
		background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 12%, $greenLight 12%, $greenLight 100%);
		/* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, #ffffff 0%, #ffffff 12%, $greenLight 12%, $greenLight 100%);

		figure {
			margin-bottom: 70px;

			.caption {
				top: auto !important;
				bottom: -19px !important;
				padding: 10px 40px;
				width: 355px;
				height: 95px;
				display: flex;
				align-items: center;
				justify-content: center;

				h1 {
					margin: 0px;
					padding: 0px;
					font-family: 'Raleway', sans-serif;
					text-transform: uppercase;

					font-size: 40px;
				}
			}
		}

		p.lead {
			font-size: 24px;
			line-height: 34px;
			text-align: left;
			color: $greyText;
			font-weight: 400;
		}
	}

	&.internal.short-header {
		/* Old browsers */
		background: -moz-linear-gradient(top, #ffffff 0%, #ffffff 14.5%, $greenLight 14.5%, $greenLight 100%);
		/* FF3.6-15 */
		background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 14.5%, $greenLight 14.5%, $greenLight 100%);
		/* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, #ffffff 0%, #ffffff 14.5%, $greenLight 14.5%, $greenLight 100%);
	}

	&.kontakt-section {
		/* Old browsers */
		background: -moz-linear-gradient(top, #ffffff 0%, #ffffff 10%, $greenLight 10%, $greenLight 100%);
		/* FF3.6-15 */
		background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 10%, $greenLight 10%, $greenLight 100%);
		/* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, #ffffff 0%, #ffffff 10%, $greenLight 10%, $greenLight 100%);

		figure .caption {
			background-color: $yellow;
			color: $white;

			h1 {
				color: $white !important;
			}
		}

	}

	&.service-page {
		background: -moz-linear-gradient(top, #ffffff 0%, #ffffff 26%, $greenLight 26%, $greenLight 100%);
		margin-bottom: 2rem;

		figure {
			margin-bottom: 00px;

			.caption h1 {
				font-size: 38px;
			}
		}
	}
}

@media (max-width: 767px) {
	.jumbotron figure .caption {
		left: -15px;
		padding: 20px;
		font-size: 30px;
		line-height: 35px;
		width: 80%;
	}

	.jumbotron.internal {
		padding-bottom: 20px;

		figure {
			margin-bottom: 55px;

			.caption {
				height: 80px;

				h1 {
					font-size: 30px;
				}
			}
		}
	}

	.jumbotron {

		h1,
		h2 {
			font-size: 30px;
			line-height: 40px;
			margin-top: 85px;
			margin-bottom: 20px;
			font-weight: 500;
		}

		p.lead {
			font-size: 20px !important;
			line-height: 30px !important;
		}

		.btn {
			width: 100%
		}

	}
}

@media (max-width: 576px) {
	.jumbotron figure .caption {
		left: -15px;
		bottom: -50px;
		padding: 15px 16px;
		font-size: 28px;
		line-height: 30px;
		width: 80%;

		span {
			font-size: 14px;
		}
	}

	.jumbotron {

		h1,
		h2 {
			font-size: 28px;
			line-height: 36px;
			margin-top: 85px;
			margin-bottom: 20px;
			font-weight: 500;
		}

		p.lead {
			font-size: 18px !important;
			line-height: 28px !important;
		}

		.btn {
			width: 100%
		}

	}

	.jumbotron.internal {
		padding-bottom: 20px;

		figure {
			margin-bottom: 70px;

			.caption {
				height: 57px;
				bottom: -48px !important;
				width: auto;
				justify-content: flex-start;
				text-align: left;
				padding: 10px 15px;

				h1 {
					font-size: 23px;
					text-align: left;
				}
			}
		}
	}
}

.multi-cards {
	margin: 0px 0;
	padding: 60px 0;

	.card {
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		position: relative;
		margin-left: 100px;
		border: 0px;

		.card-body {
			position: absolute;
			top: 38px;
			left: -115px;
			border: 0px;
			text-align: right;
			transition: transform .1s;

			/* Animation */
			&:hover {
				transform: scale(1.05);
			}
		}

		.card-img-top {
			width: 260px;
			-webkit-border-radius: 0px;
			-moz-border-radius: 0px;
			border-radius: 0px;
		}

		.card-body {
			a {
				display: flex;
				flex-direction: column;
				align-content: center;
				justify-content: space-between;
				background-color: $greenMedium;
				color: $greenDark;
				width: 184px;
				height: 146px;
				font-size: 22px;
				padding: 27px 20px 20px 20px;
				text-decoration: none;
			}

			h2 {
				text-align: left;
				font-size: 22px;
				color: $greenDark;
			}

			svg {
				width: 20px;
				height: 13px;
				fill: $greenDark;
				align-self: flex-end;
			}
		}

	}
}

@media (max-width: 1200px) {
	.multi-cards .card {
		margin-left: 10%;

		.card-img-top {
			width: 90%;
		}

		.card-body {
			left: -25%;

			a {
				width: 150px;
				height: 120px;
				font-size: 20px;
				padding: 25px 10px 10px 10px;
			}

			h2 {
				font-size: 20px;
			}
		}
	}
}

@media (max-width: 991px) {
	.multi-cards .card .card-body {
		left: -61px;
		bottom: 0px;
		top: auto;
	}
}

@media (max-width: 767px) {
	.multi-cards .card {
		margin-left: 41px;
		margin-bottom: 10px;

		.card-img-top {
			width: 100%;
		}

		.card-body {
			left: -61px;
			top: 20px;
			bottom: auto;

			a {
				width: 184px;
				height: 145px;
				font-size: 22px;
				padding: 25px 20px 20px 20px;
			}

			h2 {
				font-size: 22px;
			}
		}
	}
}


/* services */
.green-bkg {
	background-color: $greenTrasp;
}

.services {
	text-align: center;
	margin: 0;
	padding: 60px 0;

	h2 {
		font-size: 30px;
		line-height: 40px;
		color: $greyText;
		margin-bottom: 40px;

		@media (max-width: 576px) {
			font-size: 25px;
			line-height: 35px;
		}

		span {
			font-size: 18px;
			line-height: 22px;
			color: $greyMenu;
		}
	}

	svg {
		fill: $greenMedium;
		height: 57px;
		margin-bottom: 15px;
	}

	ul li {
		font-size: 18px;
		line-height: 26px;
		list-style-type: none;
		color: $greyText;
		/*border-bottom: 1px solid #d8ebda;*/
		padding: 11px 0 0 0;

		&:after {
			content: "";
			display: block;
			height: 1px;
			width: 90px;
			background-color: #d8ebda;
			margin: 13px auto 0 auto;
		}
	}

	.btn-primary {
		margin-top: 27px;

		svg {
			margin-top: 0px;
		}
	}

	.table-icons {
		margin: 10px 0;
	}
}

@media (max-width: 767px) {
	.services ul {
		margin-bottom: 40px;
	}

}

@media (max-width: 576px) {
	.services .btn-primary {
		width: 100%;
	}
}

/* slider section*/

.slider-section {
	background: #ffffff;
	margin-bottom: 2rem;
	/* Old browsers */
	background: -moz-linear-gradient(top, $greenTrasp 0%, $greenTrasp 30%, #ffffff 30%, #ffffff 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(top, $greenTrasp 0%, $greenTrasp 30%, #ffffff 30%, #ffffff 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, $greenTrasp 0%, $greenTrasp 30%, #ffffff 30%, #ffffff 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$greenTrasp', endColorstr='#ffffff', GradientType=0);
	/* IE6-9 */

	.carousel .carousel-caption,
	.carousel .carousel-caption2 {
		background-color: #ffffff;
		opacity: 0.9;
		color: #303030;
		top: 0px;
		bottom: auto;
		left: 100px;
		width: 455px;
		padding: 0px 30px 30px 30px;
		text-align: left;

		h5 {
			font-size: 24px;
			margin: 60px 0 0 0;
		}

		p {
			font-size: 18px;
			line-height: 26px;
			margin: 16px 0 0 0;

			span {
				font-size: 14px;
			}
		}

	}

	.carousel-caption {
		.btn-primary {
			background-color: transparent;
			border: 0px;
			color: $yellow;
			font-size: 18px;
			padding: 0px;
			font-weight: 600;
			margin-top: 32px;
			margin-bottom: 55px;

			svg {
				fill: $yellow !important;
			}

			&:hover,
			&:focus,
			&.focus,
			&:active {
				background-color: transparent !important;
				border: 0px;
				color: $yellow !important;
				outline: 0px !important;
				-webkit-box-shadow: 0 0 0 0rem rgba(245, 159, 11, 0.95) !important;
				-moz-box-shadow: 0 0 0 0rem rgba(245, 159, 11, 0.95) !important;
				box-shadow: 0 0 0 0rem rgba(245, 159, 11, 0.95) !important;
			}
		}
	}

	.carousel .carousel-caption2 {
		left: 583px;
		background-color: #ffffff;
		opacity: 0.9;
		color: #303030;
		top: 0px;
		bottom: auto;
		width: 455px;
		padding: 0px 30px 30px 30px;
		text-align: left;
		position: absolute;

		.btn-secondary {
			margin-top: 20px;
		}
	}
}

@media (max-width: 1200px) {
	.slider-section .carousel .carousel-caption {
		width: auto;
		left: 61px;
		max-width: 550px;
	}
}

@media (max-width: 992px) {
	.slider-section .carousel .carousel-caption {
		width: 450px;
	}
}

@media (max-width: 768px) {
	.slider-section .carousel .carousel-caption {

		left: 30px;

	}
}

@media (max-width: 552px) {
	.slider-section .carousel .carousel-caption {
		width: auto;
		left: 30px;
		right: 30px;

		h5 {
			font-size: 20px;
			margin: 25px 0 0 0;
		}

		p {
			font-size: 16px;
			line-height: 22px;
			margin: 10px 0 0 0;
		}

		.btn-primary {
			margin: 25px 0 30px 0;
		}
	}

	.slider-section .carousel .carousel-caption2 {

		h5 {
			font-size: 20px;
			margin: 25px 0 0 0;
		}

		p {
			font-size: 16px;
			line-height: 22px;
			margin: 10px 0 0 0;
		}

		.btn-primary {
			margin: 25px 0 30px 0;
		}
	}
}

/* ASIDE SLIDER */
aside {
	.slider-section {
		margin-top: 55px;
		background-color: none;

		.carousel {
			.carousel-inner {
				overflow: visible;
			}

			.carousel-caption {
				background-color: #cbe4cf;

				margin: -320px auto 20px auto;
				width: 90%;
				left: auto;
				right: auto;
				top: auto;
				padding: 20px;
				font-size: 16px;
				color: #000000;
				position: relative;
				font-weight: 500;

				h5 {
					margin: 0px;
					font-size: 24px;
				}

				p {
					font-size: 16px !important;
					line-height: 24px !important;
				}

				@media (max-width: 552px) {
					& {
						top: 5%;
					}
				}

				ul {
					margin: 0px;
					padding: 0px;

					li {
						margin: 0px;
						padding: 0px;

						&:before {
							display: none;
						}
					}
				}

				a {

					text-decoration: none;

					svg {
						width: 9px;
						height: 9px;
						margin-right: 25px;
					}

					&:hover {
						text-decoration: underline;
					}
				}

				svg {
					width: 18px;
				}

				button {
					margin-top: 1rem;
				}

				&.uber_mich {
					margin: -90px auto 20px auto;
					font-weight: 600;
					color: $greyText;
				}

			}
		}
	}
}

/* ANGEBOTE SERVICES*/
.angebote-services {
	margin: 0;
	padding: 60px 0;

	h3 {
		font-size: 24px;
		line-height: 30px;
		color: $greyText;
		margin: 0px 0px 20px 0;
		text-align: center;

		span {
			font-size: 18px;
			line-height: 22px;
			color: $greyMenu;
		}
	}

	ul li {
		font-size: 18px;
		line-height: 25px;
		list-style-type: none;
		color: $greyText;
		padding: 12px 0 12px 20px;
		position: relative;

		&:before {
			content: "";
			display: block;
			position: absolute;
			left: 0px;
			top: 22px;
			height: 6px;
			width: 6px;
			background-color: #9e91b9;
			margin: 0;
			-webkit-border-radius: 20px;
			-moz-border-radius: 20px;
			border-radius: 20px;
		}
	}

}

/* article */

.article-content {
	padding: 5px 0px 0 0;
	font-size: 18px;
	line-height: 26px;

	.container {
		border-bottom: 1px solid #efeff0;
		padding-bottom: 30px;
	}

	h3 {
		font-size: 24px;
		margin: 55px 0px 10px 0px;
		padding: 0;
	}

	p {
		font-size: 18px;
		line-height: 26px;
		margin: 10px 0;
	}

	ul {
		li {
			font-size: 18px;
			line-height: 26px;
			margin: 10px 0;
			padding: 10px 0 10px 20px;
			position: relative;

			&:before {
				content: "";
				display: block;
				position: absolute;
				left: 0px;
				top: 19px;
				height: 5px;
				width: 5px;
				background-color: $greenMedium;
				margin: 0;
				-webkit-border-radius: 20px;
				-moz-border-radius: 20px;
				border-radius: 20px;
			}
		}
	}

	.cv {
		&>div {
			padding-top: 17px;
			padding-bottom: 17px;
		}

		@media (max-width: 991px) {
			&>div {
				padding-top: 5px;
				padding-bottom: 5px;

				&:nth-child(odd) {
					padding-top: 30px;
					font-weight: 600;
				}
			}
		}
	}

}

.table-icons {
	margin-top: 50px;

	&>div {
		text-align: center;
		margin: 1rem 0;

		h4 {
			font-family: 'Nothing You Could Do', cursive;
			font-size: 20px;
			font-weight: 600;
		}

		figure {
			height: 125px;
			display: flex;
			justify-content: center;
			align-content: center;

			img {
				max-height: 125px;
			}
		}
	}
}

@media (min-width: 991.98px) and (max-width: 1199px) {
	.angebote-slider {
		.carousel-item {

			.carousel-caption,
			.carousel-caption2 {
				width: 43%;
				max-width: 43%;

				h5 {
					margin-top: 25px;
				}

				p {
					font-size: 17px !important;
					line-height: 25px !important;
					margin: 10px 0 0 0 !important;
				}
			}

			.carousel-caption {
				left: 5%;
			}

			.carousel-caption2 {
				left: auto;
				right: 5%;
			}
		}
	}
}

@media (max-width: 991px) {
	.backgoundimg-slider {
		img {
			display: none !important;
		}

		.carousel-item {
			background: #ffffff url("../img/slider-angebote.jpg") no-repeat right top;
			background-size: cover;

			.carousel-caption,
			.carousel-caption2 {
				position: static !important;
				width: 90% !important;
				max-width: 90%;
				left: auto !important;
				right: auto !important;
				margin: 25px 5%;
				float: left;

				h5 {
					margin-top: 25px;
				}

			}
		}
	}
}

/* kontakt*/
.kontakt-form {
	margin: 34px 0px;

	.form-row {

		&>.col,
		&>[class*="col-"] {
			margin-bottom: 20px !important;

		}
	}

	.form-control {
		padding: 27px 20px !important;
		border-radius: 30px;
		font-size: 14px;
	}

	textarea.form-control {
		padding: 20px 20px !important;
		border-radius: 15px;
		font-size: 14px;
		height: 206px;
	}

	.form-text {
		margin-bottom: 16px;
	}

	@media (min-width: 1200px) {
		.g-recaptcha {
			float: right;
		}
	}

	small {
		line-height: 22px;
	}
}

.kontakt-foto {
	margin-top: 117px;

	@media (max-width: 767px) {
		margin-top: 20px;
	}
}

.kontakt-section {
	text-align: left;

	.evelyn-pic {
		width: 160px;
		height: 160px;
		border-radius: 100px;
		overflow: hidden;
		border: 5px solid $white;
		float: left;
		margin: 0 30px 20px 0px;

		img {
			width: 100%;
			height: 100%;
		}

		@media (max-width: 576px) {
			& {
				float: none;
				width: 200px;
				height: 200px;
				margin: auto;
			}
		}
	}

	ul {
		margin: 20px 0;
		padding: 0px;

		li {
			margin: 0px;
			padding: 0px;
			font-weight: 400;

			&:before {
				display: none;
			}
		}

		svg {
			width: 18px;
			fill: #6ab072;
			margin-right: 5px;
		}
	}

	.row>div {
		font-size: 18px;
		font-weight: 600;

		@media (max-width: 576px) {
			text-align: center;
		}
	}

	.addresse {
		@media (min-width: 992px) {
			display: flex;
			align-items: flex-end;
			justify-content: right;

		}

		@media (min-width: 576px) and (max-width: 991.98px) {
			padding-left: 205px;
		}

		font-weight: 400 !important;

		a {
			display: table;
			margin-top: 10px;

			@media (max-width: 576px) {
				display: block;
			}

			svg {
				width: 9px;
				height: 9px;
				margin-right: 25px;
				fill: #6ab072;
			}
		}
	}
}

.service-page {
	ul {
		li {
			font-size: 18px;
			line-height: 26px;
			margin: 5px 0;
			padding: 5px 0 5px 20px;
			position: relative;

			&:before {
				content: "";
				display: block;
				position: absolute;
				left: 0px;
				top: 16px;
				height: 5px;
				width: 5px;
				background-color: $greenMedium;
				margin: 0;
				-webkit-border-radius: 20px;
				-moz-border-radius: 20px;
				border-radius: 20px;
			}
		}
	}
}

/* logo story*/
.logo-story {
	text-align: center;
	margin: 0;
	padding: 60px 0;

	.btn-primary {
		margin-top: 27px;

		svg {
			margin-top: 0px;
		}
	}

	.table-icons {
		margin: 20px 0 0 0;
	}
}

aside .logo-story {
	padding: 0px !important;
	margin-top: 55px;

	.container {
		border-bottom: 0px;
	}

	.table-icons {


		&>div {
			text-align: center;
			margin: 1rem 0;

			h4 {
				font-family: 'Nothing You Could Do', cursive;
				font-size: 18px;
				font-weight: 600;
			}

			figure {
				height: 90px;
				display: flex;
				justify-content: center;
				align-content: center;

				img {
					max-height: 90px;
				}
			}
		}
	}
}

/* cookies*/
.cc-window.cc-floating {
	background-color: $greenMedium !important;
	opacity: 0.95;
	color: $greenDark !important;
	font-family: 'Raleway', sans-serif;
	font-weight: 500;

	a {
		color: $greenDark !important;
	}

	.cc-link {
		opacity: 1.0;
	}

	.cc-btn {
		color: #ffffff !important;
		border-radius: 20px;
		text-transform: uppercase;
		background-color: $yellow !important;
		font-weight: 500 !important;

		&:hover {
			text-decoration: none;
		}
	}
}

.thank-you {
	padding: 0.5rem 0;
	display: none;
	font-weight: 400;

	p {
		display: table;
		background-color: $greenLight;
		border-radius: 1rem;
		padding: 2rem 1.5em;
	}
}
