@import "portal-variables";

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {

	.kontakt-section ul svg,
	aside .slider-section .carousel .carousel-caption svg {
		width: 18px;
		height: 18px;
	}

	.multi-cards .card img {
		max-height: 375px;
		height: 100%;
	}

	.jumbotron.internal figure .caption {
		width: auto;
	}

	.kontakt-form .form-control {
		padding: 0 20px !important;
		height: 50px;
	}

}
