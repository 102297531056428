$greenDark: #285337;
$greenMedium: #b9dcbd;
$greenLight: #f1f8f1;
$greenTrasp: #f8fbf8;
$greenHover: #8ab291;
$greyMenu: #727072;
$greyText: #464646;
$yellow: #f59f0b;
$darkYellow: #d67b0d;
$violetLight: #f5f4f9;
$white: #ffffff;


$primary: $greenMedium;
$secondary: $yellow;
