@import "portal-variables";
/* main header*/

.main-header {
	position: -webkit-sticky;
	position: sticky !important;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
	background-color: #ffffff;

	.bg-light {
		background-color: #ffffff !important;
	}

	.navbar-brand {
		display: flex;
		justify-content: center;
		margin-bottom: 1rem;

		a {
			color: $greenDark;
			font-size: 20px;
			display: flex;
			align-items: center;
			line-height: 25px;
			text-align: left;
			transition: all 0.35s;

			&:hover {
				text-decoration: none;
			}

			span {
				color: $greyMenu;
				font-size: 18px;
				transition: all 0.35s;
			}

			img {
				height: 112px;
				padding-right: 28px;
				transition: all 0.35s;
			}
		}
	}

	.navbar-light .navbar-nav .nav-item {
		.nav-link {
			font-size: 18px;
			color: $greyMenu;
			text-transform: uppercase;
			font-family: 'Raleway', sans-serif;
			font-weight: 500;
			padding-right: 1.188rem;
			padding-left: 1.188rem;

			&:hover {
				color: $greenHover;
			}
		}

		&.active .nav-link {
			color: $greenHover;
		}

		&:last-child .nav-link {
			color: #ffffff;
			background-color: $yellow;
			-webkit-border-radius: 30px;
			-moz-border-radius: 30px;
			border-radius: 30px;
			margin-left: 1.188rem;

			&:hover {
				background-color: $darkYellow;
			}
		}

	}

	.navbar-toggler {
		border: 0px;
		padding: 0px;
		width: 30px;
		height: 20px;
		position: relative;

		svg {
			position: absolute;


			&.open-btn-mobile {
				fill: $greenMedium;
				height: 20px;
				width: 30px;

				top: 0px;
				left: 0px;
				transition: all 0.4s ease;
				-webkit-transform: rotate(-45deg) scale(0, 0);
				transform: rotate(-45deg) scale(0, 0);
			}

			&.close-btn-mobile {
				fill: #9d91b9;
				height: 20px;
				width: 20px;
				display: block;
				top: 0px;
				left: 5px;
				transition: all 0.4s ease;
				-webkit-transform: rotate(0deg) scale(1, 1);
				transform: rotate(0deg) scale(1, 1);
			}

		}

		&.collapsed {
			.open-btn-mobile {
				display: block;
				transition: all 0.4s ease;
				-webkit-transform: rotate(0deg) scale(1, 1);
				transform: rotate(0deg) scale(1, 1);
			}

			.close-btn-mobile {
				transition: all 0.4s ease;
				-webkit-transform: rotate(45deg) scale(0, 0);
				transform: rotate(45deg) scale(0, 0);
			}
		}

		&:focus {
			outline: 0px;
		}
	}
}

@media (min-width: 768px) {
	.main-header.sticky-header .navbar-brand a {
		img {
			height: 70px;
			transition: all 0.35s;
		}

		font-size: 18px;
		line-height: 23px;
		transition: all 0.35s;

		span {
			font-size: 14px;
			transition: all 0.35s;
		}
	}
}

@media (max-width: 991px) {
	.main-header .navbar-light .navbar-nav .nav-item .nav-link {
		font-size: 16px;
		padding-right: 1.07rem;
		padding-left: 1.07rem;
	}
}

@media (max-width: 767px) {

	.navbar-expand-md,
	.navbar-expand-lg {
		justify-content: space-between;
	}

	.navbar-brand {
		flex: 0 0 60%;
		text-align: left;
		padding-left: 0px;
		justify-content: left;
	}

	.main-header .navbar-brand {
		display: flex;
		justify-content: left;
		margin-bottom: 0rem;

		a img {
			height: 70px;
			padding-right: 25px;
		}
	}
}

@media (max-width: 576px) {
	.main-header .navbar-brand a {
		font-size: 18px;
		line-height: 23px;

		span {
			font-size: 14px;
		}

		img {
			padding-right: 15px;
		}
	}
}

@media (max-width: 330px) {
	.main-header .navbar-brand a {
		font-size: 16px;
		line-height: 20px;

		span {
			font-size: 12px;
		}

		img {
			height: 50px;
			padding-right: 10px;
		}
	}
}

#navbarCollapse {
	margin-bottom: 1rem;
}

.main-header.sticky-header {
	-webkit-box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.55);
	-moz-box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.55);
	box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.55);
}

@media (max-width: 767px) {
	.main-header {

		.navbar {
			padding-top: 0px;
			padding-bottom: 0px;

			.navbar-brand {
				margin: 13px 0 !important;

			}

			#navbarCollapse {
				background-color: $violetLight;
				margin-left: -15px;
				margin-right: -15px;
				padding: 1rem;
				margin-bottom: 0px;
				box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.55);

				ul {
					li.nav-item {
						text-align: center;
						padding: 9px 0px;
						margin: 0px;

						.nav-link {
							font-size: 18px;
							font-weight: 600;
						}

						&:last-child .nav-link {
							margin-left: 0px;
						}
					}
				}
			}

		}
	}
}
